import {Auth} from '@domain/model/Auth/Auth';
import {AuthLoginGoogle} from '@domain/model/Auth/AuthLoginGoogle';
import {RegisterUserCommand} from '@domain/model/Auth/RegisterUserCommand';
import {setAuth} from '@infra/api/authManager/authManager';
import {fetch} from '@infra/api/fetch';
import {AuthLoginFaceBook} from '@domain/model/Auth/AuthLoginFaceBook';
import {AuthError} from '@domain/model/Auth/AuthError';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const recoverPassword = async ({email}: {email: string}): Promise<Record<string, string>> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({email}),
    headers,
  };

  const response = await fetch(`/api/auth/forgotpwd/`, requestOptions);
  if (response.status === 404) {
    return Promise.reject({errorKey: response.status});
  }

  return response.json();
};

const registerUser = async (registerUserCommand: RegisterUserCommand): Promise<Auth> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(registerUserCommand),
    headers,
  };
  const response = await fetch(`/api/register/`, requestOptions);
  if (!response.ok) {
    let code, message;
    try {
      ({code, message} = await response.json());
    } catch (e) {
      code = '';
      message = '';
    }
    return Promise.reject({errorKey: response.status, code, message});
  }
  return response.json();
};

const loginWithGoogle = async (loginCommand: AuthLoginGoogle): Promise<string> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(loginCommand),
    headers,
  };

  const response = await fetch(`/api/v1/login-google`, requestOptions);

  if (!response.ok) {
    return Promise.reject({errorKey: response.status});
  }

  const {token} = await response.json();
  await setAuth(token);
  return Promise.resolve(token);
};

const registerWithFaceBook = async (params: AuthLoginFaceBook): Promise<string> =>
  await authenticateInFb('register', params);
const loginWithFaceBook = async (accessToken: string): Promise<string> =>
  await authenticateInFb('login', {token: accessToken});

const authenticateInFb = async (
  authenticationType: string,
  body: AuthLoginFaceBook | {token: string},
): Promise<string> => {
  try {
    const response = await fetch(`/api/auth/${authenticationType}/facebook`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    });
    if (response.ok) {
      return (await response.json()).token as string;
    } else {
      return Promise.reject(response.status === 403 ? AuthError.USER_EXISTS : AuthError.UNKNOWN);
    }
  } catch {
    return Promise.reject(AuthError.UNKNOWN);
  }
};

export const apiAuth = {recoverPassword, loginWithGoogle, registerUser, registerWithFaceBook, loginWithFaceBook};
